@import "../../assets/styles/main.scss";
.contact-us {
  .heading {
    text-align: center;
    color: $primary-color;
    font-size: 64px;
    font-weight: 300;
    line-height: 110px; /* 171.875% */
    text-transform: uppercase;
  }
  .contact-form {
    background: #fff;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 50px 60px;
    width: 75%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .form-control {
      color: $primary-color !important;

      border-bottom: 1px solid $primary-color !important;
      border-right: none !important;
      border-top: none !important;
      border-left: none !important;
      border-radius: 0 !important;

      &:focus {
        box-shadow: none !important;
        color: $primary-color !important;
      }
    }
  }
}
