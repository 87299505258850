.product {
  border: 4px solid var(--Secondary, #ae8625);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
  .product_name {
    color: var(--primary, #013a57);
    font-size: 36px;
    font-weight: 700;
  }
  .brochure {
    border-radius: 40px;
    background: radial-gradient(
      296.8% 173.09% at 99.61% -1.45%,
      #037dbb 0%,
      #01273b 99.99%
    );
    padding: 5px 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }
  .product-details {
    p {
      color: #444;
      font-size: 20px;
      font-weight: 400;
      line-height: 148.8%;
    }
  }
  .product_desc {
    color: #444;

    font-size: 20px;
    font-weight: 400;
  }
  .call_back_icon {
    position: absolute;
    top: 0%;
    right: 5%;
    width: auto;
  }
}

.main-image {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  height: 400px;
}

.main-image.fade-out {
  opacity: 0;
}

.thumbnail {
  width: 77px;
  height: 77px;
  border: 1.5px solid #737373;
  padding: 10px;
}
