.gold-industry {
  .image_animate {
    position: relative;
    width: 100%; /* Add this */
    // height: calc(100vh - 130px); /* Add this */
    height: 89vh;
    @media (max-width: 768px) {
      height: auto;
    }

    overflow: hidden; /* Ensure content does not overflow */
    .row {
      display: flex;
    }

    .col-lg-3 {
      img {
        opacity: 0;
        animation: fadeIn 2s forwards;
        height: 89vh;

        width: 100%;
        @media (max-width: 768px) {
          height: auto;
        }
      }

      &:nth-child(1) img {
        animation-delay: 0s;
      }

      &:nth-child(2) img {
        animation-delay: 1s;
      }

      &:nth-child(3) img {
        animation-delay: 2s;
      }

      &:nth-child(4) img {
        animation-delay: 3s;
      }
    }

    &.show-full {
      .col-lg-3 {
        display: none;
      }

      .col-lg-12 {
        img {
          height: 89vh;

          width: 100%;
          @media (max-width: 768px) {
            height: auto;
          }
        }
        opacity: 0;
        width: 0;
        animation: revealFromLeft 1s forwards;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes revealFromLeft {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 100%;
    }
  }
  .content {
    top: 50%;
    left: 55%;
    width: 100%;
    transform: translate(-50%, -50%); /* Center the video */
    .heading {
      color: #fff;
      font-size: 108px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .sub-head {
      color: #fff;
      margin-top: 50px;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .description {
      color: #fff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      margin-top: 30px;
      font-weight: 400;
      line-height: 150.7%; /* 48.224px */
    }
  }
  .product-list {
    .title {
      border-radius: 60px;
      width: max-content;
      background: var(
        --Secondary,
        linear-gradient(
          90deg,
          #ae8625 0%,
          #f7ef8a 36%,
          #d2ac47 70%,
          #edc967 100%
        )
      );
      padding: 10px 30px;
      color: #013a57;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .about {
      color: #737373;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
  }
}
