@import "../../../assets/styles/main.scss"; // Import color variables

.secondary_button {
  border-radius: 0px;
  background: #d2ac47;
  color: $primary-color;
  border: none;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;

  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;

  // Define hover effect
  &:hover {
    color: $primary-color;

    border: none;
    background: $secondary-gradient; // Change background color to primary color on hover
    // img.icon {
    //   filter: contrast(0%);
    // }
    // Start transition from the right
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
