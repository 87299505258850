@import "../../assets//styles/main.scss";

.why-us {
  color: $primary-color;
  .heading {
    font-size: 90px;
    font-weight: 700;
    line-height: 100px; /* 111.111% */
    text-transform: uppercase;
    text-align: center;
  }
  .content {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px; /* 125% */
    margin-top: 60px;
  }
  .image_animate {
    .row {
      display: flex;
    }

    .col-lg-3 {
      img {
        opacity: 0;
        animation: fadeIn 3s forwards;
      }

      &:nth-child(1) img {
        animation-delay: 0s;
      }

      &:nth-child(2) img {
        animation-delay: 2s;
      }

      &:nth-child(3) img {
        animation-delay: 4s;
      }

      &:nth-child(4) img {
        animation-delay: 6s;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
