@import "../../../assets/styles/main.scss"; // Import color variables

.custom-navbar {
  background: $primary-color;
  padding: 10px;
  .custom-width {
    width: 80%;
  }
  // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  .nav-link {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 1276px) {
      font-size: 14px;
    }
    &:hover {
      color: $secondary-color;
    }
  }
  .dropdown-menu {
    border-radius: 10px !important;
    background: white !important;
    padding: 22px;
    .dropdown-item {
      color: $primary-color;
      &:hover {
        color: $primary-color !important;
      }
    }
  }
  .callUs {
    color: #fafafa;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    a {
      text-decoration: none !important;
      // color: #fafafa !important;
      &:hover {
        color: $secondary-color !important;
      }
    }
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $secondary-color !important;
    }
  }
}
.navbar-brand {
  @media (max-width: 400px) {
    width: 77%;
  }
}
