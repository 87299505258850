// src/assets/styles/_colors.scss

$primary-color: #013a57; // Define your primary color
$secondary-color: #ffd160;
$secondary-gradient: linear-gradient(
  90deg,
  #ae8625 0%,
  #f7ef8a 36%,
  #d2ac47 70%,
  #edc967 100%
); // Define your secondary color

$text-color: #000000;
