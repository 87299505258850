@import "../../../assets/styles/main.scss"; // Import color variables

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translate(-50%, -50%); /* Center the video */
  object-fit: cover;
  z-index: -1;
}
.hero-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, #0000004d 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 83.6vh; /* Ensure the hero section takes up the full height of the viewport */
  overflow: hidden; /* Hide any overflow content */

  .content {
    position: relative; /* Ensure child elements are positioned relative to this container */
    z-index: 1; /* Ensure content is above the video */
    .tagline {
      background: white;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      font-family: Almarai;
      font-size: 58px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 60px;
      @media (max-width: 768px) {
        font-size: 30px;
        padding-bottom: 20px;
      }
      @media (max-width: 487px) {
        font-size: 20px;
        padding-bottom: 20px;
      }
    }
    .category_card {
      background: $primary-color;
      backdrop-filter: blur(40px);
      padding: 25px;
      flex-wrap: wrap;
      align-self: stretch;
      height: 100%;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        padding: 15px;
      }

      .category_name {
        color: #fff;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 13px;
          margin-top: 10px;
        }
      }
      div {
        position: relative;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
        @media (max-width: 425px) {
          width: 272px;
          height: 113px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    height: 89.5vh; /* Remove fixed height for smaller screens */
    align-items: flex-start; /* Align content to the top */
    padding-top: 50px; /* Add space at the top */
  }

  .content {
    margin: 20px; /* Adjust margin for smaller screens */
  }
}
