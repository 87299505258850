@import "../../assets/styles/main.scss";

.about-us {
  .heading {
    color: $primary-color;

    font-size: 90px;
    font-weight: 300;
    line-height: 100px; /* 111.111% */
    text-transform: uppercase;
  }
  .content {
    color: $primary-color;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px; /* 150% */
  }
}
