.vector1 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
}

.vector2 {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

.logo {
  width: 20%;
}

.call_back {
  color: var(--primary, #013a57);
  font-size: 24px;
  font-weight: 400;
  line-height: 125.8%;
  margin-top: 30px;
}

.ant-modal-content {
  padding: 50px !important;
  .form-control {
    border-color: #013a57 !important;
  }
}
