@import "../../../assets/styles/main.scss"; // Import color variables

.about-company {
  .ant-row {
    padding: 80px !important;
  }
  .title {
    color: $primary-color;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 35px; /* 159.091% */
  }
  .value {
    color: $primary-color;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }
  img {
    height: 77px;
    width: 77px;
  }
}
