@import "../../../assets/styles/main.scss"; // Import color variables

.navbar {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  padding: 10px 40px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
  .navbar-toggler-icon {
    background-image: url("../../../assets/images/navbar_icon.svg") !important;
  }
  .navbar-toggler {
    border: none !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 1276px) {
      font-size: 14px;
    }
    &:hover {
      color: $secondary-color;
    }
  }
  .navbar-nav .show > .nav-link {
    color: $secondary-color;
  }
  .menu-overlay {
    background: linear-gradient(
      154deg,
      #ae8625 0%,
      #f7ef8a 36%,
      #d2ac47 70%,
      #edc967 100%
    );
    align-items: center;
    justify-content: center;
  }
  .dropdown-menu {
    border-radius: 10px !important;
    background: #fff !important;
    padding: 22px;
    .dropdown-item {
      color: $primary-color;
      margin-top: 10px;
      &:hover {
        background-color: transparent !important;
        color: $secondary-color !important;
        font-weight: 700;
      }
    }
  }
  .callUs {
    color: #fafafa;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    a {
      text-decoration: none !important;
      // color: #fafafa !important;
      &:hover {
        color: $secondary-color !important;
      }
    }
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $secondary-color !important;
      .call-icon {
        filter: invert(100%); // invert the colors to make it black
      }
    }
  }
  .menu-overlay {
    position: fixed;
    padding: 40px;
    top: 0;
    right: 0; /* Open from the right */
    bottom: 0;
    width: 18%; /* Adjust as needed */
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
    transform: translateX(100%); /* Initially hide off the screen */
    transition: transform 0.3s ease; /* Smooth animation */
    @media (max-width: 1024px) {
      width: 30%;
    }

    @media (max-width: 768px) {
      padding: 20px;
    }
    /* Responsive adjustments */
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }

    &.open {
      transform: translateX(0); /* Slide in from the right */
    }

    p {
      color: black;
      font-size: 24px; /* Adjust font size */
      margin: 30px;
      margin-top: 35px;
      @media (max-width: 768px) {
        font-size: 20px;
        margin-top: 30px;
      }
      @media (max-width: 425px) {
        font-size: 20px;
        margin-top: 35px;
      }
    }
  }
}
.navbar-brand {
  img {
    @media (max-width: 768px) {
      width: 55%;
    }
  }
}
