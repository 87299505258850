@import "../../../assets/styles/main.scss";
.footer {
  padding: 85px 50px 85px 50px;
  background: $secondary-gradient;
  .contact_us {
    .sub_heading {
      color: #000;

      font-size: 26px;
      font-weight: 700;
      line-height: 40px; /* 153.846% */

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .content {
      color: #000;

      font-size: 26px;
      font-weight: 400;
      line-height: 40px; /* 153.846% */
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .links {
    .heading {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .footer-slider {
    border-radius: 20px;
    border: 3px solid #ffc800;
    margin-right: 10px;
  }
  .slick-slide {
    div {
      margin-right: 10px;
    }
  }
}
.copyright {
  // padding: 50px auto;
  background-color: $primary-color;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  // width: 100%;
  padding: 30px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
